<template>
  <v-app>
    <div style="position: fixed; height: 100vh; background-color: #222">
      <v-img src="@/assets/login.jpg" width="100vw" cover />
    </div>
    <v-container id="fundo" class="p-0 fill-height" fluid>
      <v-row class="layout-login-row">
        <v-col cols="12" :style="$vuetify.breakpoint.xs ? 'padding: 4px' : ''">
          <v-row class="fill-height" align="center" justify="center">
            <v-col cols="12" sm="8" md="5" lg="4" style="padding: 0px">
              <v-container
                :style="$vuetify.breakpoint.xs ? 'padding: 4px' : ''"
              >
                <v-card color="white" style="padding: 12px">
                  <v-container class="pb-2">
                    <v-row
                      class="layout-logos-login fill-height px-4 height"
                      align="center"
                      justify="center"
                    >
                      <v-img
                        class="logo-form-login educampo"
                        src="img/milk-plan.png"
                        height="120px"
                        contain
                      />
                    </v-row>
                  </v-container>
                  <div v-if="!loader">
                    <v-form
                      id="form_recuperarSenha"
                      ref="form_recuperarSenha"
                      v-model="valid"
                      @submit.prevent="recuperarSenha"
                    >
                      <v-container class="p-4">
                        <v-text-field
                          v-model="email"
                          :rules="[rules.required]"
                          light
                          label="Email"
                          name="email"
                          prepend-inner-icon="mdi-email"
                        />
                        <v-row justify="center" class="pt-2">
                          <v-btn
                            color="success"
                            class="mr-4"
                            @click="alterarSenha"
                          >
                            Enviar
                          </v-btn>
                          <v-btn color="error" class="mr-4" @click="voltar">
                            Cancelar
                          </v-btn>
                        </v-row>
                      </v-container>
                    </v-form>
                  </div>
                  <v-container v-else class="pa-6">
                    <v-row justify="space-around">
                      <v-progress-circular
                        :size="100"
                        :width="7"
                        color="primary"
                        :indeterminate="true"
                      />
                    </v-row>
                  </v-container>
                </v-card>
              </v-container>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-snackbar
        v-model="snackbar"
        multi-line
        top
        :timeout="3500"
        color="error"
      >
        {{ message }}
        <v-btn dark @click="snackbar = false"> Fechar </v-btn>
      </v-snackbar>
    </v-container>
  </v-app>
</template>
<script>
export default {
  props: {
    backgroundImage: {
      type: String,
      default: 'static/img/login.jpg',
    },
  },
  data() {
    return {
      email: null,
      emailRules: [v => /.+@.+/.test(v) || ''],
      requiredRules: [v => !!v || ''],
      user: null,
      snackbar: false,
      message: '',
      loader: false,
      valid: null,
      rules: {
        required: value => !!value || 'Obrigatório.',
        emailMatch: () => 'O email ou a senha estão incorretos',
      },
    }
  },
  created() {
    localStorage.clear()
  },
  methods: {
    voltar() {
      this.$router.push('/login')
    },
    alterarSenha() {
      if (this.email == null) {
        this.Swal.fire({
          title: 'Atenção...',
          text: 'Informe um e-mail válido para recuperação da senha!',
          icon: 'error',
        })
      } else {
        this.loader = true
        this.api.save
          .recuperarsenha(this.email)
          .then(response => {
            if (response.success) {
              this.Swal.fire({
                title: 'Email enviado...',
                text:
                  'Um e-mail de recuperação foi enviado, por favor, verifique sua caixa de entrada.',
                icon: 'success',
              })
              this.loader = false
              this.$router.push('/login')
            } else {
              this.Swal.fire({
                title: 'Atenção...',
                text:
                  'Não foi possível enviar o e-mail com a senha por algum erro ocorrido no e-mail.',
                icon: 'error',
              })
              this.loader = false
            }
          })
          .catch(error => {
            this.Swal.fire({
              icon: 'error',
              title: 'Atenção',
              html: error.message,
            })
            this.loader = false
          })
      }
    },
  },
}
</script>
<style scope lang="scss">
.senha {
  color: rgb(40, 57, 211);
}
.senha:hover {
  color: rgb(29, 115, 245);
}

.outline-login .container .v-input .v-input__control .v-input__slot {
  border: 2px solid #005bb9;
}

.logo-form-login.educampo .v-image__image--cover {
  background-size: contain;
  background-position: center !important;
}

html {
  overflow: auto;
}

.headline {
  font-family: 'Poppins', sans-serif !important;
}

.display-grid {
  display: grid;
  height: auto !important;
  .flex {
    background-color: white;
    padding: 7px;
    margin-bottom: 70%;
    .v-responsive.v-image {
      margin-top: 6px;
      padding: 0px 0.7px;
    }
  }
}

.apresentacao {
  font-size: 24px;
  font-family: 'Poppins', sans-serif;
}

.layout-login-row {
  margin-right: 0px !important;
  margin-left: 0px !important;
}

@media (min-width: 41em) {
  #fundo {
    background-size: auto;
  }
} /* 656px */

@media (min-width: 47em) {
  #fundo {
    background-size: auto;
  }
} /* 752px */

@media (min-width: 68em) {
  #fundo {
    background-size: auto;
  }
} /* 1088px */

@media (min-width: 82em) {
  #fundo {
    background-size: auto;
  }
} /* 1312px */

@media (min-width: 100em) {
  #fundo {
    background-size: contain;
  }
  .layout-logos-login {
    align-items: center !important;
  }
  .tela-login.flex .v-card {
    height: 316px;
  }
  .headline {
    font-size: 34px !important;
  }
  .logo-form-login.educampo {
    height: 110px !important;
  }
} /* 1600px */
</style>
